import { FC, useMemo } from "react";
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  BillableRequestApi,
  BillingRate,
  BillingRateType,
} from "@practice/sdk";
import classNames from "classnames";
import { last } from "lodash";
import moment, { Moment } from "moment";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useBillingRatesContext } from "@contexts/billing-rates";
import { useGetSessionBillingCycles } from "@hooks/data/billable-request";
import { useContact } from "@hooks/data/clients";
import useLogger from "@hooks/use-logger";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import { useCheckScreenSize } from "@hooks/use-window-size";
import { CUSTOM_FIELD_ICONS } from "@lib/constants/customFieldIcons";
import { displayNameFromContact } from "@lib/contacts";

import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";
import ClientPicker from "@components/ClientPicker";
import Dropdown from "@components/Dropdown";
import { PastDatesPicker } from "@components/Form/DatePicker";
import CalendarIcon from "@components/Icons/CalendarIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import LogIcon from "@components/Icons/LogIcon";
import NoteIcon from "@components/Icons/NoteIcon";

import { useGetBillableRequestsContext } from "./BillableRequestsContext";
import DurationField from "./DurationField";
import Input from "./Input";

const BillingRateField: FC = () => {
  const {
    field: { value, onChange },
  } = useController({ name: "billingRate", rules: { required: true } });
  const { isMD } = useCheckScreenSize();

  const { activeBillingRates } = useBillingRatesContext();

  const options = useMemo(() => {
    return activeBillingRates.filter(
      (rate) => rate.type === BillingRateType.Billable
    );
  }, [activeBillingRates]);

  return (
    <div className="relative">
      <Dropdown
        menuClasses="bottom-10 left-0 max-h-[500px] overflow-y-auto"
        buttonClasses="!border-transparent"
        content={
          <Input
            Icon={isMD && <NoteIcon />}
            placeholder="Type"
            value={value?.title}
            className={classNames(!isMD && "!max-w-[80px] overflow-hidden")}
          />
        }
      >
        <div className="bg-white flex flex-col w-60 text-sm">
          <div className="text-grey-500 p-3">Type</div>
          {options.map((option) => {
            const icon = option.icon ?? "ic_info";
            const OptionIcon = CUSTOM_FIELD_ICONS[icon];
            return (
              <button
                onClick={() => onChange(option)}
                className="px-3 py-2 flex text-left items-center w-full hover:bg-action-950 gap-2"
                key={option.id}
              >
                <OptionIcon className="h-5 w-5 flex-none" />
                <div>
                  {option.title}
                  {value?.id === option.id && (
                    <CheckIcon className="ml-auto h-4 w-4" />
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};

const DescriptionField: FC = () => {
  const {
    field: { value, onChange },
  } = useController({ name: "description" });

  return (
    <input
      className="bg-transparent border border-none placeholder-grey-800 focus:ring-0 p-0 w-full"
      placeholder="Describe your request"
      name="billable-request-description"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const ClientField: FC = () => {
  const { watch } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({ name: "clientId" });

  const clientId = watch("clientId");

  const { contact } = useContact(clientId);

  return (
    <ClientPicker
      handleClientSelection={onChange}
      contactId={value}
      className="w-60"
      customListboxClassNames="!shadow-none !bg-transparent"
      noLabel
      customPlaceholder={
        <Input
          placeholder="Client"
          Icon={<ClientAvatar size="xxsmall" client={contact} />}
          value={displayNameFromContact(contact)}
        />
      }
      includeClientOrganizations
      customAddButton
    />
  );
};

const DateField: FC = () => {
  const {
    field: { value, onChange },
  } = useController({ name: "date", rules: { required: true } });

  const { data } = useGetSessionBillingCycles();
  const { isMD } = useCheckScreenSize();

  const min = last(data)?.start;

  if (!min) return null;

  return (
    <div className="hidden-date-input">
      <PastDatesPicker
        inputIconPosition="before"
        CustomInputIcon={() => (
          <Input
            Icon={isMD && <CalendarIcon className="h-5 w-5" />}
            value={
              value ? moment(value).format(`MMM D${isMD ? ", YYYY" : ""}`) : ""
            }
            placeholder="Date"
          />
        )}
        isOutsideRange={(date) => moment(min).isAfter(date, "day")}
        date={value ? moment(value) : moment()}
        showClearDate={false}
        onChange={(e) => onChange(e.target.value)}
        placeholder=""
        displayYear={true}
        openDirection="up"
        overrideMobilePortal
      />
    </div>
  );
};

type FormValues = {
  clientId?: string;
  date?: Moment;
  billingRate?: BillingRate;
  duration?: string;
  description?: string;
};

const getDefaultValue = (contactId?: string): FormValues => {
  return {
    clientId: contactId,
    date: undefined,
    billingRate: undefined,
    duration: "",
    description: "",
  };
};

const AddBillableRequest: FC = () => {
  const router = useRouter();
  const { organization } = useAuth();
  const { isMD } = useCheckScreenSize();
  const { contactId } = router.query;
  const methods = useForm({
    defaultValues: getDefaultValue(contactId as string),
    shouldUnregister: false,
    mode: "all",
  });

  const { refresh } = useGetBillableRequestsContext();
  const snackbar = useSnackbar();

  const { logger } = useLogger("AddBillableRequest");

  const generateRequestId = useRequestIdGenerator("AddBillableRequest");

  const billalbeRequestApi = useSDKApi(BillableRequestApi);

  const loading = methods.formState.isSubmitting;

  const onSubmit = async (data: FormValues) => {
    try {
      await billalbeRequestApi.submitBillableRequest({
        organizationId: organization!.id,
        submitBillableRequestRequest: {
          billingRateId: data.billingRate!.id,
          clientId: data.clientId!,
          date: data.date!.toDate(),
          duration: parseInt(data.duration!),
          description: data.description,
        },
        xRequestId: generateRequestId(),
      });
      refresh();
      snackbar.showMessage("Request submitted");
      methods.reset(getDefaultValue(data.clientId));
    } catch (e) {
      snackbar.showWarning("Failed to submit request");
      logger.error({ e }, "Failed to submit billable request");
    }
  };

  const disabled = !methods.formState.isValid;

  return (
    <div
      className={classNames(
        isMD && "bg-grey-950 mt-4 rounded-2xl p-4 border border-grey-900"
      )}
    >
      <FormProvider {...methods}>
        {isMD ? (
          <DescriptionField />
        ) : (
          <div className="text-grey-500 flex flex-col gap-4 p-6 border-dashed border-b-2">
            <LogIcon />
            <div className="flex flex-col gap-1">
              <h2 className="font-medium text-sm">Quick request entry</h2>
              <p className="text-xs">
                Switch to desktop to review your submitted requests
              </p>
            </div>
          </div>
        )}
        <div
          className={classNames(
            "flex",
            isMD ? "mt-4" : "flex-col bg-grey-950 h-full p-6 pb-12"
          )}
        >
          <div className="flex items-center space-x-2">
            <BillingRateField />
            <DateField />
            <DurationField />
            <ClientField />
          </div>

          <Button
            className={classNames(
              isMD ? "ml-auto" : "mt-2",
              disabled && "!bg-grey-900"
            )}
            primary
            onClick={methods.handleSubmit(onSubmit)}
            small
            isLoading={loading}
            disabled={disabled}
            type="submit"
          >
            Submit request
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default AddBillableRequest;
