import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useHasFeature from "@hooks/use-has-feature";
import { FeatureNames } from "@lib/constants/featureNames";

import { Button } from "@components/Button";
import AIChatIcon from "@components/Icons/AIChatIcon";

const AIChatButton = () => {
  const { oid, isOwner } = useAuth();
  const { hasFullAccess } = useAccessType();
  const [hasAIChatFeature] = useHasFeature(oid, FeatureNames.aiChat);

  const handleClick = () => {
    window.open("/ai-chat", "_self");
  };

  const hasAccess = hasAIChatFeature && (isOwner || hasFullAccess);

  if (!hasAccess) {
    return null;
  }

  return (
    <div id="ai-chat-button">
      <Button
        className=" shadow-thin group w-12 h-12 p-0"
        icon={<AIChatIcon className="w-[77px] h-[77px] hover:opacity-90" />}
        white
        circle
        data-heap-event-name="ai_chat_open_button_clicked"
        onClick={handleClick}
      />
    </div>
  );
};

export default AIChatButton;
